var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',{staticClass:"pt-5"},[_c('v-carousel',{attrs:{"hide-delimiters":""},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.anterior(_vm.model)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)]}},{key:"next",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.siguiente(_vm.model)}}},on),[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}}])},_vm._l((_vm.items),function(item,index){return _c('v-carousel-item',{key:index,attrs:{"src":(_vm.url_api + "/files/images/" + (_vm.items[_vm.model].Uid_item) + "/" + (_vm.items[_vm.model].foto)),"contain":""}})}),1),_c('v-slide-group',{staticClass:"pa-4",attrs:{"mandatory":"","show-arrows":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.items),function(miniImage,index){return _c('v-slide-item',{key:index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"ma-4",attrs:{"height":"100","width":"100"},on:{"click":toggle}},[_c('v-img',{staticStyle:{"height":"100%"},attrs:{"src":(_vm.url_api + "/files/images/" + (miniImage.Uid_item) + "/.Metadatos/" + (miniImage.foto))}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[_c('v-scale-transition',[(active)?_c('v-icon',{attrs:{"color":"white","size":"48"},domProps:{"textContent":_vm._s('mdi-image-filter-center-focus-strong')}}):_vm._e()],1)],1)],1)],1)]}}],null,true)})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }