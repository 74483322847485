<template>
  <div>
    <v-sheet class="pt-5">
      <v-carousel hide-delimiters>
        <template v-slot:prev="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="anterior(model)">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </template>
        <template v-slot:next="{ on }">
          <v-btn icon v-on="on" @click="siguiente(model)">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </template>
        <v-carousel-item v-for="(item, index) in items" :key="index"
          :src="`${url_api}/files/images/${items[model].Uid_item}/${items[model].foto}`" contain>
        </v-carousel-item>
      </v-carousel>

      <v-slide-group v-model="model" class="pa-4" mandatory show-arrows>
        <v-slide-item v-for="(miniImage, index) in items" :key="index" v-slot="{ active, toggle }">
          <v-card class="ma-4" height="100" width="100" @click="toggle">
            <v-img :src="`${url_api}/files/images/${miniImage.Uid_item}/.Metadatos/${miniImage.foto}`"
              style="height: 100%">
              <v-row class="fill-height" align="center" justify="center">
                <v-scale-transition>
                  <v-icon v-if="active" color="white" size="48" v-text="'mdi-image-filter-center-focus-strong'">
                  </v-icon>
                </v-scale-transition>
              </v-row>
            </v-img>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
  </div>
</template>

<style>

  .v-slide-item--active .v-responsive .v-responsive__content {
    background-color: rgba(0, 0, 0, 0.445);
  }

</style>

<script>
  export default {
    name: "galeria",
    props: ['items', 'model'],
    data: () => ({
      url_api: process.env.VUE_APP_URL_API,
    }),
    methods: {
      anterior(valor) {
        if (valor <= 0) {
          this.model = this.items.length - 1;
        } else {
          this.model = valor - 1;
        }
      },
      siguiente(valor) {
        if (valor >= this.items.length - 1) {
          this.model = 0;
        } else {
          this.model = valor + 1;
        }
      },
    },
  };
</script>