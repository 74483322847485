<template>
    <div>
        <!-- alerta CRUD -->
        <v-snackbar v-model="alerta" :color="colorAlerta" right top :timeout="timeout">
            {{ textoAlerta }}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="alerta = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
        <!-- fin alerta CRUD -->
        <v-data-table :headers="headers" :items="activos" :search="search" :footer-props="{
            showFirstLastPage: true, 
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            'items-per-page-text': 'N° Paginas',
            'items-per-page-all-text':'Todos',
            pageText: '{1} de {2}',
            }" class="elevation-1 rounded-lg mt-5">
            <template v-slot:[`item.persona`]="{ item }">{{ item.nombre }} {{ item.apellido }}</template>
            <template v-slot:top>
                <v-toolbar flat class="rounded-lg">
                    <v-toolbar-title>LISTA DE ITEMS</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-btn color="primary" dark @click="dialog=!dialog">
                        <v-icon>mdi-content-save-outline</v-icon> Agregar
                    </v-btn>
                    <v-btn @click="exportarXLS" class="mx-1" icon color="success">
                        <v-icon large>mdi-file-excel</v-icon>
                    </v-btn>
                    <v-btn @click="exportarPDF" class="mx-1" icon color="error">
                        <v-icon large>mdi-file-pdf-box</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar:" outlined hide-details
                        clearable></v-text-field>
                    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                        <v-card>
                            <v-toolbar color="primary" dark>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                                <v-spacer></v-spacer>
                                <v-btn icon dark @click="dialog = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-toolbar>
                            <v-list three-line subheader class="pt-8">
                                <v-container>
                                    <v-form ref="form" v-model="valid" lazy-validation>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" md="6">
                                                    <v-autocomplete @change="tipoItem()" v-model="itemTipo" :items="tiposItems"
                                                        :rules="reglaSlcTipItem" prepend-inner-icon="mdi-clipboard"
                                                        label="Tipo Item*" item-text="nombre" item-value="Uid"
                                                        return-object outlined clearable required>
                                                        <template v-slot:selection="data">
                                                            <span v-bind="data.attrs" :input-value="data.selected">
                                                                {{data.item.alias}} -
                                                                {{ data.item.nombre }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:item="data">

                                                            <template>
                                                                {{data.item.alias}} - {{data.item.nombre}}
                                                            </template>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-row align="center">
                                                        <v-col cols="11">
                                                            <v-text-field v-model="editedItem.codigo"
                                                                :rules="reglaCodigo" label="Codigo*"
                                                                prepend-inner-icon="mdi-format-list-numbered"
                                                                :disabled="!isEdiCodigo" outlined clearable>
                                                            </v-text-field>
                                                        </v-col>
                                                        <v-col>
                                                            <v-slide-x-reverse-transition mode="out-in">
                                                                <v-icon :key="`icon-${isEdiCodigo}`"
                                                                    :color="isEdiCodigo ? 'success' : 'info'"
                                                                    @click="isEdiCodigo = !isEdiCodigo"
                                                                    v-text="isEdiCodigo ? 'mdi-check-outline' : 'mdi-circle-edit-outline'">
                                                                </v-icon>
                                                            </v-slide-x-reverse-transition>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-autocomplete v-model="itemEstado" :items="estadosItems"
                                                        :rules="reglaSlcEstItem"
                                                        prepend-inner-icon="mdi-clipboard-check-multiple"
                                                        label="Estado Item*" item-text="nombre" item-value="Uid"
                                                        outlined clearable required>
                                                        <template v-slot:selection="data">
                                                            <span v-bind="data.attrs" :input-value="data.selected">
                                                                {{data.item.alias}} -
                                                                {{ data.item.nombre }}
                                                            </span>
                                                        </template>
                                                        <template v-slot:item="data">
                                                            <template>
                                                                {{data.item.alias}} - {{data.item.nombre}}
                                                            </template>
                                                        </template>
                                                    </v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-autocomplete v-model="itemTipoEq" :rules="reglaSlcTipoEq"
                                                        :items="tiposEquipos" item-text="nombre" item-value="Uid"
                                                        prepend-inner-icon="mdi-format-list-bulleted-type"
                                                        label="Tipo de Equipo*" outlined clearable required>
                                                    </v-autocomplete>
                                                </v-col>

                                                <v-col cols="12">
                                                    <v-textarea prepend-inner-icon="mdi-format-align-right"
                                                        label="Descripcion" v-model="editedItem.descripcion" auto-grow
                                                        rows="10" row-height="15" outlined clearable>
                                                    </v-textarea>
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-autocomplete v-model="itemMarca" :rules="reglaSlcMarcaItem"
                                                        :items="marcas" item-text="nombre" item-value="Uid"
                                                        prepend-inner-icon="mdi-alert-box" label="Marca*" outlined
                                                        clearable required>
                                                    </v-autocomplete>
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-autocomplete v-model="itemProveedor"
                                                        :rules="reglaSlcProveedorItem" :items="proveedores"
                                                        item-text="nombre" item-value="Uid"
                                                        prepend-inner-icon="mdi-truck-delivery" label="Proveedor*"
                                                        outlined clearable required>
                                                    </v-autocomplete>
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-autocomplete v-model="itemFabricante" :items="fabricantes"
                                                        item-text="nombre" item-value="Uid"
                                                        prepend-inner-icon="mdi-factory" label="Fabricante" outlined
                                                        clearable>
                                                    </v-autocomplete>
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-text-field v-model="editedItem.modelo"
                                                        prepend-inner-icon="mdi-sticker-alert" label="Modelo" outlined
                                                        clearable>
                                                    </v-text-field>
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-text-field v-model="editedItem.serial"
                                                        prepend-inner-icon="mdi-barcode" label="Serial" outlined
                                                        clearable>
                                                    </v-text-field>
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-subheader style="height:20px">
                                                        <v-icon>mdi-calendar-alert</v-icon> Vida Util
                                                    </v-subheader>
                                                    <v-slider v-model="editedItem.vidaUtil" min="0" max="50"
                                                        thumb-label="always">
                                                    </v-slider>
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-autocomplete @change="listarBodega()" v-model="itemSede"
                                                        :rules="reglaSlcSedeItem" :items="sedes" item-text="nombre"
                                                        item-value="Uid" prepend-inner-icon="mdi-office-building-marker"
                                                        label="Sede*" outlined clearable required>
                                                    </v-autocomplete>
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-autocomplete :disabled="!isEditing" v-model="itemBodega"
                                                        :rules="reglaSlcBodegaItem" :items="bodegas" item-text="nombre"
                                                        item-value="Uid" prepend-inner-icon="mdi-garage-variant"
                                                        label="Bodega*" outlined clearable required>
                                                    </v-autocomplete>
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-dialog ref="dialogDate" v-model="modal"
                                                        :return-value.sync="editedItem.fechaFabricacion" persistent
                                                        width="290px">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field v-model="editedItem.fechaFabricacion"
                                                                prepend-inner-icon="mdi-calendar-today"
                                                                label="Fecha de Fabricacion" readonly v-bind="attrs"
                                                                v-on="on" outlined clearable></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="editedItem.fechaFabricacion" scrollable
                                                            locale="es">
                                                            <v-spacer></v-spacer>
                                                            <v-btn text color="primary" @click="modal = false">
                                                                Cancel
                                                            </v-btn>
                                                            <v-btn text color="primary"
                                                                @click="$refs.dialogDate.save(editedItem.fechaFabricacion)">
                                                                OK
                                                            </v-btn>
                                                        </v-date-picker>
                                                    </v-dialog>

                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-dialog ref="dialogDate2" v-model="modal2"
                                                        :return-value.sync="editedItem.fechaUso" persistent
                                                        width="290px">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field v-model="editedItem.fechaUso"
                                                                prepend-inner-icon="mdi-calendar-star"
                                                                label="Fecha de Uso" readonly v-bind="attrs" v-on="on"
                                                                outlined clearable></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="editedItem.fechaUso" scrollable
                                                            locale="es">
                                                            <v-spacer></v-spacer>
                                                            <v-btn text color="primary" @click="modal2 = false">
                                                                Cancel
                                                            </v-btn>
                                                            <v-btn text color="primary"
                                                                @click="$refs.dialogDate2.save(editedItem.fechaUso)">
                                                                OK
                                                            </v-btn>
                                                        </v-date-picker>
                                                    </v-dialog>
                                                </v-col>

                                                <v-col cols="12">
                                                    <v-autocomplete v-model="itemUso" @change="otroUso()" :items="usos"
                                                        item-text="nombre" item-value="Uid"
                                                        prepend-inner-icon="mdi-clipboard-arrow-right" label="Uso"
                                                        outlined clearable>
                                                    </v-autocomplete>
                                                </v-col>

                                                <v-col cols="12" v-if="(formUso==1)">
                                                    <v-textarea prepend-inner-icon="" :label="tituloFormUso"
                                                        v-model="editedItem.otroUso" auto-grow rows="10" row-height="15"
                                                        outlined clearable>
                                                    </v-textarea>
                                                </v-col>

                                                <v-col cols="12">
                                                    <v-text-field v-model="editedItem.kit" prepend-inner-icon=""
                                                        label="Nombre del Kit" outlined clearable>
                                                    </v-text-field>
                                                </v-col>

                                                <v-col cols="12">
                                                    <v-textarea prepend-inner-icon="mdi-format-list-bulleted"
                                                        label="Informacion de los Componentes Kit"
                                                        v-model="editedItem.informacionKit" auto-grow rows="10"
                                                        row-height="15" outlined clearable>
                                                    </v-textarea>
                                                </v-col>

                                                <v-col cols="12">
                                                    <v-textarea prepend-inner-icon=""
                                                        label="Certificaciones/Cumple Normas"
                                                        v-model="editedItem.cumpleNorma" auto-grow rows="10"
                                                        row-height="15" outlined clearable>
                                                    </v-textarea>
                                                </v-col>

                                                <v-col cols="12">
                                                    <v-textarea prepend-inner-icon="" label="Especificaciones Tecnicas"
                                                        v-model="editedItem.especificaciones" auto-grow rows="10"
                                                        row-height="15" outlined clearable>
                                                    </v-textarea>
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-text-field type="text" @keyup="miles" v-model="editedItem.valor"
                                                        :rules="reglaValor" prepend-inner-icon="mdi-currency-usd-circle"
                                                        label="Valor*" outlined clearable maxlength="21" required>
                                                    </v-text-field>
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-text-field v-model="editedItem.factura" :rules="reglaNumFactura"
                                                        prepend-inner-icon="" label="Numero Factura*" outlined clearable
                                                        required>
                                                    </v-text-field>
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-text-field v-model="editedItem.capacidad" prepend-inner-icon=""
                                                        label="Capacidad / Resistencia General" outlined clearable>
                                                    </v-text-field>
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-text-field v-model="editedItem.lote" prepend-inner-icon=""
                                                        label="Lote" outlined clearable>
                                                    </v-text-field>
                                                </v-col>

                                                <v-col cols="12" md="6">
                                                    <v-dialog ref="dialogDate3" v-model="modal3"
                                                        :return-value.sync="editedItem.fechaCompra" persistent
                                                        width="290px">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field v-model="editedItem.fechaCompra"
                                                                :rules="reglaFecCompra" prepend-inner-icon=""
                                                                label="Fecha Compra*" readonly v-bind="attrs" v-on="on"
                                                                outlined clearable required>
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="editedItem.fechaCompra" scrollable
                                                            :max="fechaActual" locale="es">
                                                            <v-spacer></v-spacer>
                                                            <v-btn text color="primary" @click="modal3 = false">
                                                                Cancel
                                                            </v-btn>
                                                            <v-btn text color="primary"
                                                                @click="$refs.dialogDate3.save(editedItem.fechaCompra)">
                                                                OK
                                                            </v-btn>
                                                        </v-date-picker>
                                                    </v-dialog>
                                                </v-col>

                                                <v-col cols="3">
                                                    <v-switch v-model="editedItem.manual" value="1" color="primary"
                                                        label="Manual del Fabricante"></v-switch>
                                                </v-col>

                                                <v-col cols="3">
                                                    <v-switch v-model="editedItem.certificacion" value="1"
                                                        color="primary" label="Certificacion del Fabricante"></v-switch>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-file-input @change="vistaPrevia" v-model="image" accept="image/*"
                                                        multiple prepend-icon="" prepend-inner-icon=""
                                                        label="Imagen General*" outlined required>
                                                        <template v-slot:selection="{ text }">
                                                            <v-chip small label color="primary">
                                                                {{ text }}
                                                            </v-chip>
                                                        </template>
                                                    </v-file-input>
                                                    <v-row>
                                                        <v-col v-for="(item,i) in url" :key="i" cols="1">
                                                            <v-img :src="item.src" aspect-ratio="1"></v-img>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>

                                                <v-col cols="12">
                                                    <v-file-input @change="vistaPrevia" v-model="imageE"
                                                        accept="image/*" multiple prepend-icon="" prepend-inner-icon=""
                                                        label="Imagen Etiqueta*" outlined required>
                                                        <template v-slot:selection="{ text }">
                                                            <v-chip small label color="primary">
                                                                {{ text }}
                                                            </v-chip>
                                                        </template>
                                                    </v-file-input>
                                                    <v-row>
                                                        <v-col v-for="(item,i) in urlE" :key="i" cols="1">
                                                            <v-img :src="item.src" aspect-ratio="1"></v-img>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>

                                            <v-divider class="mt-3"></v-divider>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="error" class="mr-4" @click="close">
                                                    Cancelar
                                                </v-btn>
                                                <v-btn :disabled="!valid" color="success" class="mr-4" @click="guardar">
                                                    Guardar
                                                </v-btn>
                                            </v-card-actions>
                                        </v-container>
                                    </v-form>
                                </v-container>
                            </v-list>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="550px">
                        <v-card>
                            <v-card-title class="text-h6 font-weight-ligth text--secondary">
                                <v-icon x-large left color="error">
                                    mdi-alert-octagon-outline
                                </v-icon>
                                ¿Estas seguro que deceas eliminar este campo?
                            </v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" @click="closeDelete">
                                    Cancelar
                                </v-btn>
                                <v-btn color="success" @click="deleteItemConfirm">
                                    Aceptar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
                <v-dialog v-model="dialogImage" persistent max-width="600px">
                    <v-card>
                        <v-toolbar color="primary" dark>
                            <v-card-title>
                                <span class="text-h5">{{tituloimagen}}</span>
                            </v-card-title>
                            <v-spacer></v-spacer>
                            <v-btn icon dark @click="dialogImage = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <v-container>
                                <v-item-group multiple v-model="imgEliminar">
                                    <v-container>
                                        <v-row>
                                            <v-col v-for="(h, index) in images" :key="index" cols="12" md="4">
                                                <v-item v-slot="{ active, toggle }">
                                                    <v-card class="d-flex align-center" height="200" @click="toggle">
                                                        <v-img
                                                            :src="`${url_api}/files/images/${h.Uid_item}/.Metadatos/${h.foto}`"
                                                            style="height: 100%">
                                                            <v-row class="fill-height" align="center" justify="center">
                                                                <v-scroll-y-transition>
                                                                    <v-icon v-if="active" color="red" size="48"
                                                                        v-text="'mdi-close-circle-outline'"></v-icon>
                                                                </v-scroll-y-transition>
                                                            </v-row>
                                                        </v-img>
                                                    </v-card>
                                                </v-item>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-item-group>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="error" @click="dialogImage = false">
                                Cancelar
                            </v-btn>
                            <v-btn color="primary" @click="eliminarImage">
                                Elmininar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="dialogFormato" persistent max-width="800px">
                    <v-card>
                        <v-toolbar color="primary" dark>
                            <v-card-title>
                                <span class="text-h5">{{tituloimagen}}</span>
                            </v-card-title>
                            <v-spacer></v-spacer>
                            <v-btn icon dark @click="dialogFormato = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <v-container class="mt-3">
                                <v-row>
                                    <v-col cols="12 grey lighten-3">
                                        <p class="text-center display-1">Documentos</p>
                                    </v-col>
                                    <v-col cols="3" :elevation="5" v-for="(documento, key) in documentos" :key="key">
                                        <v-card outlined>
                                            <a :href="`${url_api}/files/images/${documento.item}/${documento.archivo}`"
                                                target="_blank">
                                                <v-img
                                                    :src="`${url_api}/files/images/${documento.item}/.Metadatos/${documento.imagen}`">
                                                </v-img>
                                            </a>
                                            <v-card-subtitle>
                                                <span v-text="documento.nombre"></span>
                                            </v-card-subtitle>
                                        </v-card>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="12 grey lighten-3">
                                        <p class="text-center display-1">Inspección</p>
                                    </v-col>
                                    <v-col cols="3" :elevation="5" v-for="(file, key) in files" :key="key">
                                        <v-card outlined>
                                            <a :href="`${url_api}/files/images/${file.item}/${file.archivo}`"
                                                target="_blank">
                                                <v-img
                                                    :src="`${url_api}/files/images/${file.item}/.Metadatos/${file.imagen}`">
                                                </v-img>
                                            </a>
                                            <v-card-subtitle>
                                                <span v-text="file.nombre"></span>
                                            </v-card-subtitle>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>
            <template v-slot:body.prepend>
                <tr>
                    <td>
                        <v-text-field type="text" placeholder="N/A" disabled></v-text-field>
                    </td>
                    <td>
                        <v-text-field type="text" placeholder="N/A" disabled></v-text-field>
                    </td>
                    <td>
                        <v-text-field type="text" placeholder="N/A" disabled></v-text-field>
                    </td>
                    <td>
                        <v-text-field type="text" placeholder="N/A" disabled></v-text-field>
                    </td>
                    <td>
                        <v-autocomplete @change="listarBodega()" v-model="sede" :items="sedes" item-text="nombre"
                            item-value="nombre" prepend-inner-icon="mdi-office-building-marker" label="Sede" clearable>
                        </v-autocomplete>

                    </td>
                    <td>
                        <v-autocomplete v-model="bodega" :items="fBodegas" item-text="nombre" item-value="nombre"
                            prepend-inner-icon="mdi-garage-variant" label="Bodega" clearable>
                        </v-autocomplete>
                    </td>
                    <td>
                        <v-text-field type="text" placeholder="N/A" disabled></v-text-field>
                    </td>
                    <td>
                        <v-text-field type="text" placeholder="N/A" disabled></v-text-field>
                    </td>
                </tr>
            </template>
            <template v-slot:item.a="{ item }">
                <div v-if="!item.foto">
                    <div v-for="(img, index) in imgs" :key="index">
                        <v-img :src="img.foto" style="width: 100px; height:100px">
                            <template v-slot:placeholder>
                                <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey"></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                    </div>
                </div>
                <div class="pa-2" v-else>
                    <v-dialog transition="dialog-bottom-transition" max-width="80%">
                        <template v-slot:activator="{ on, attrs }">
                            <v-img :src="`${url_api}/files/images/${item.Uid}/.Metadatos/${item.foto}`"
                                style="width: 100px; height:100px" v-bind="attrs" v-on="on" @click="galeriaImagen(item)"
                                contain>
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate color="grey"></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                        </template>
                        <template v-slot:default="dialog">
                            <v-card>
                                <v-toolbar color="primary" dark>
                                    <v-card-title>
                                        <span class="text-h5">ITEM - {{item.codigo}}</span>
                                    </v-card-title>
                                    <v-spacer></v-spacer>
                                    <v-btn icon dark @click="dialog.value = false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-card-text>
                                    <galeria :items="images" :model="0" />
                                </v-card-text>
                            </v-card>
                        </template>
                    </v-dialog>
                </div>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-btn small color="blue" class="" @click="editItem(item)">
                    <v-icon small color="white"> mdi-pencil </v-icon>
                </v-btn>
                <v-btn small color="error" class="mx-2 my-1" @click="deleteItem(item)">
                    <v-icon small> mdi-delete </v-icon>
                </v-btn>
                <v-menu offset-y>
                    <template v-slot:activator="{ attrs, on }">
                        <v-btn color="warning" class="white--text ma-5" v-bind="attrs" v-on="on">
                            MÁS
                        </v-btn>
                    </template>
                    <v-list>
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <v-list-item v-bind="attrs" v-on="on" v-if="item.foto"
                                    @click="galeriaImagen(item); dialogImage = true" link>
                                    <v-list-item-title>
                                        <v-icon color="warning">mdi-image-remove</v-icon>
                                    </v-list-item-title>
                                </v-list-item>
                            </template>
                            <span>Eliminar Imagen</span>
                        </v-tooltip>

                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <v-list-item v-bind="attrs" v-on="on" @click="formatos(item)" link>
                                    <v-list-item-title>
                                        <v-icon color="success">mdi-file</v-icon>
                                    </v-list-item-title>
                                </v-list-item>
                            </template>
                            <span>Formatos</span>
                        </v-tooltip>

                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <v-list-item v-bind="attrs" v-on="on" @click="hojaVida(item)" link>
                                    <v-list-item-title>
                                        <v-icon color="primary">mdi-file</v-icon>
                                    </v-list-item-title>
                                </v-list-item>
                            </template>
                            <span>Hoja de vida</span>
                        </v-tooltip>
                    </v-list>
                </v-menu>
            </template>

            <template v-slot:no-data>
                <v-btn color="primary" @click="listarItem"> Reset </v-btn>
            </template>

        </v-data-table>
    </div>
</template>

<script>
    import {
        mapState,
        mapActions
    } from "vuex";
    import jsPDF from 'jspdf';
    import autoTable from 'jspdf-autotable';

    import galeria from '../components/view/Galeria';
    import imagePre from "@/assets/yedi.png";
    export default {
        name: "About",
        components: {
            galeria
        },
        data: () => ({
            alerta: false,
            textoAlerta: '',
            colorAlerta: '',
            timeout: 2000,
            search: '',
            // formulario
            url_api: process.env.VUE_APP_URL_API,
            imgEliminar: [],
            dialogImage: false,
            dialogFormato: false,
            url: [],
            urlE: [],
            imgs: [{
                foto: imagePre
            }],
            filesAll: [],
            files: [],
            documentosAll: [],
            documentos: [],
            image: [],
            imageE: [],
            UiItem: null,
            isEdiCodigo: false,
            fechaActual: new Date().toISOString().substr(0, 10),
            tituloFormUso: 'Uso',
            formUso: 0,
            formTipo: 0,
            tituloimagen: '',
            isEditing: null,
            dialogGaleria: false,
            dialogDate: false,
            modal: false,
            dialogDate2: false,
            modal2: false,
            dialogDate3: false,
            modal3: false,
            valid: true,
            tiposItems: [],
            estadosItems: [],
            marcas: [],
            proveedores: [],
            sedes: [],
            bodegasAll: [],
            bodegas: [],
            fBodegas: [],
            fabricantes: [],
            usos: [],
            tiposEquipos: [],
            itemTipo: {
                nombre: ' EW - Equipos de Trabajo ',
                Uid: 2
            },
            itemEstado: null,
            itemMarca: null,
            itemProveedor: null,
            itemSede: null,
            itemBodega: null,
            itemFabricante: null,
            itemUso: null,
            itemTipoEq: null,
            reglaVidaUtil: [
                val => val > 0 || `I don't believe you!`
            ],

            reglaValor: [
                v => !!v || 'El valor del item no puede ser cero',
                v => (v != 0),
            ],

            reglaNumFactura: [
                v => !!v || 'El numero de la factura del item es requerido',
            ],

            reglaCodigo: [
                v => !!v || 'El codigo del item es requerido',
            ],

            reglaFecCompra: [
                v => !!v || 'La fecha de la compra del item es requerido',
            ],

            reglaSlcTipItem: [
                v => !!v || 'El tipo del item es requerido',
            ],

            reglaSlcEstItem: [
                v => !!v || 'El estado del item es requerido',
            ],

            reglaSlcMarcaItem: [
                v => !!v || 'La marca del item es requerido',
            ],

            reglaSlcProveedorItem: [
                v => !!v || 'El proveedor del item es requerido',
            ],

            reglaSlcSedeItem: [
                v => !!v || 'La sede del item es requerido',
            ],

            reglaSlcBodegaItem: [
                v => !!v || 'El Bodega del item es requerido',
            ],

            reglaSlcTipoEq: [
                v => !!v || 'El tipo de quipo del item es requerido',
            ],

            widgets: null,
            tituloGaleria: "",
            model: 0,
            images: [],
            allImages: [],
            codigo: "",
            marca: "",
            descripcion: "",
            sede: "",
            bodega: "",
            persona: "",
            dialog: false,
            dialog1: false,
            dialog2: false,
            dialogDelete: false,
            activos: [],
            editedIndex: -1,
            editedItem: {
                codigo: '',
                serial: '',
                manual: 0,
                certificacion: 0,
                descripcion: '',
                informacionKit: '',
                otroUso: '',
                cumpleNorma: '',
                especificaciones: '',
                vidaUtil: 0,
                valor: '0',
                factura: '',
                modelo: '',
                lote: '',
                kit: '',
                capacidad: '',
                fechaCompra: null,
                fechaFabricacion: null,
                fechaUso: null,
            },
            defaultItem: {
                codigo: '',
                serial: '',
                manual: 0,
                certificacion: 0,
                descripcion: '',
                informacionKit: '',
                otroUso: '',
                cumpleNorma: '',
                especificaciones: '',
                vidaUtil: 0,
                valor: '0',
                factura: '',
                modelo: '',
                lote: '',
                kit: '',
                capacidad: '',
                fechaCompra: null,
                fechaFabricacion: null,
                fechaUso: null,
            },
            numeroCodigo: 0,
        }),
        computed: {
            ...mapState(["token", "usuario"]),
            headers() {
                return [{
                        text: "Images",
                        value: "a",
                        sortable: false
                    },
                    {
                        text: "Codigo",
                        value: "codigo",
                    },
                    {
                        text: "Marca",
                        value: "marca",
                    },
                    {
                        text: "Descripcion",
                        value: "descripcion",
                    },
                    {
                        text: "Sede",
                        value: "sede",
                        filter: (value) => {
                            if (this["sede"] == null) {
                                return this.activos;
                            } else {
                                return (value + "").toLowerCase().includes(this["sede"].toLowerCase());
                            }
                        },
                    },
                    {
                        text: "Bodega",
                        value: "bodega",
                        filter: (value) => {
                            if (this["bodega"] == null) {
                                return this.activos;
                            } else {
                                return (value + "").toLowerCase().includes(this["bodega"].toLowerCase());
                            }
                        },
                    },
                    {
                        text: "Usuario",
                        value: "persona",
                    },
                    {
                        text: "Actions",
                        value: "actions",
                        sortable: false
                    },
                ];
            },
            formTitle() {
                return this.editedIndex === -1 ? "Nuevo Item" : "Editar Item " + this.editedItem.codigo;
            },
        },
        watch: {
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
        },
        created() {
            this.listarItem();
            this.tipoItem();
            this.todasImagenes();
            this.listarTipItem();
            this.listarEstadoItem();
            this.listarMarcaItem();
            this.listarProveedorItem();
            this.listarSede();
            this.listarFabricante();
            this.listarUso();
            this.listarTipoEquipo();

        },
        methods: {
            ...mapActions(['cerrarSesion']),

            // customFilter(items, search, filter) {
            //     return Object.keys(filter).some(key => {
            //         if ((filter[key] != null) && (filter[key]) && (filter[key].toString()
            //                 .toLowerCase().includes(search.toString().toLowerCase()))) {
            //             return filter;
            //         }
            //     })
            // },

            async exportarXLS() {
                try {
                    let body = [];
                    let bodyt = [];
                    if (this.search != '') {
                        this.activos.forEach(element => {
                            if (this.search != '') {
                                Object.keys(element).some(key => {
                                    if ((element[key] != null) && (element[key]) && (element[key]
                                            .toString().toLowerCase().includes(this.search
                                                .toString().toLowerCase()))) {

                                        body.push(element);

                                    }
                                });
                            } else {
                                this.body.forEach(element => {
                                    body.push(element)
                                })
                            }
                        });
                    } else {
                        body = this.activos;
                    }

                    body.forEach(element => {
                        if (this.codigo != '') {
                            if (element.codigo != null && typeof element.codigo !== 'boolean' && element
                                .codigo.toString().toLowerCase().indexOf(this.codigo.toString()
                                    .toLowerCase()) !== -1) {

                                bodyt.push(element)
                            }
                        } else {
                            bodyt.push(element)
                        }
                    })
                    body = bodyt;
                    bodyt = [];

                    body.forEach(element => {
                        if (this.marca != '') {
                            if (element.marca != null && typeof element.marca !== 'boolean' && element
                                .marca
                                .toString().toLowerCase().indexOf(this.marca.toString().toLowerCase()) !== -
                                1) {

                                bodyt.push(element)
                            }
                        } else {
                            bodyt.push(element)
                        }
                    })
                    body = bodyt;
                    bodyt = [];

                    body.forEach(element => {
                        if (this.descripcion != '') {
                            if (element.descripcion != null && typeof element.descripcion !== 'boolean' &&
                                element.descripcion.toString().toLowerCase().indexOf(this.descripcion
                                    .toString().toLowerCase()) !== -1) {

                                bodyt.push(element)
                            }
                        } else {
                            bodyt.push(element)
                        }
                    })
                    body = bodyt;
                    bodyt = [];

                    body.forEach(element => {
                        if (this.sede != '') {
                            if (element.sede != null && typeof element.sede !== 'boolean' && element
                                .sede.toString().toLowerCase().indexOf(this.sede.toString()
                                    .toLowerCase()) !== -1) {

                                bodyt.push(element)
                            }
                        } else {
                            bodyt.push(element)
                        }
                    })
                    body = bodyt;
                    bodyt = [];

                    body.forEach(element => {
                        if (this.bodega != '') {
                            if (element.bodega != null && typeof element.bodega !== 'boolean' && element
                                .bodega.toString().toLowerCase().indexOf(this.bodega.toString()
                                    .toLowerCase()) !== -1) {

                                bodyt.push(element)
                            }
                        } else {
                            bodyt.push(element)
                        }
                    })
                    body = bodyt;
                    bodyt = [];

                    await fetch(`${this.url_api}/item/export`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            },
                            body: JSON.stringify(body)
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                window.location.href =
                                    `${this.url_api}/files/formatos/${response.body}`
                            } else if (response.error == ('!token' ||
                                    'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },


            exportarPDF() {
                let columns = ['Código', 'Descripción', 'Marca', 'Serial', 'Valor', 'Vida Util', 'Nombre',
                    'Sede', 'Estado', 'Imagen'
                ];
                let body = [];
                let bodyt = [];
                if (this.search != '') {
                    this.activos.forEach(element => {
                        if (this.search != '') {
                            Object.keys(element).some(key => {
                                if ((element[key] != null) && (element[key]) && (element[key]
                                        .toString()
                                        .toLowerCase().includes(this.search.toString()
                                            .toLowerCase()))) {
                                    body.push(element);
                                }
                            });
                        } else {
                            this.body.forEach(element => {
                                body.push(element)
                            })
                        }
                    });
                } else {
                    body = this.activos;
                }

                body.forEach(element => {
                    if (this.codigo != '') {
                        if (element.codigo != null && typeof element.codigo !== 'boolean' && element
                            .codigo
                            .toString().toLowerCase().indexOf(this.codigo.toString().toLowerCase()) !==
                            -1) {
                            bodyt.push(element)
                        }
                    } else {
                        bodyt.push(element)
                    }
                })
                body = bodyt;
                bodyt = [];

                body.forEach(element => {
                    if (this.marca != '') {
                        if (element.marca != null && typeof element.marca !== 'boolean' && element
                            .marca.toString().toLowerCase().indexOf(this.marca.toString().toLowerCase()) !== -1
                        ) {

                            bodyt.push(element)
                        }
                    } else {
                        bodyt.push(element)
                    }
                })
                body = bodyt;
                bodyt = [];

                body.forEach(element => {
                    if (this.descripcion != '') {
                        if (element.descripcion != null && typeof element.descripcion !== 'boolean' && element
                            .descripcion.toString().toLowerCase().indexOf(this.descripcion.toString()
                                .toLowerCase()) !==
                            -1) {

                            bodyt.push(element)
                        }
                    } else {
                        bodyt.push(element)
                    }
                })
                body = bodyt;
                bodyt = [];

                body.forEach(element => {
                    if (this.sede != '') {
                        if (element.sede != null && typeof element.sede !== 'boolean' && element
                            .sede.toString().toLowerCase().indexOf(this.sede.toString().toLowerCase()) !== -1) {

                            bodyt.push(element)
                        }
                    } else {
                        bodyt.push(element)
                    }
                })
                body = bodyt;
                bodyt = [];

                body.forEach(element => {
                    if (this.bodega != '') {
                        if (element.bodega != null && typeof element.bodega !== 'boolean' && element
                            .bodega.toString().toLowerCase().indexOf(this.bodega.toString().toLowerCase()) !== -
                            1) {

                            bodyt.push(element)
                        }
                    } else {
                        bodyt.push(element)
                    }
                })
                body = bodyt;
                bodyt = [];

                body.forEach(element => bodyt.push([element.codigo, element.descripcion, element.marca, element
                    .serial, `$${element.valor}`, `${element.vidaUtil} Año(s)`,
                    `${element.nombre} ${element.apellido}`,
                    element.sede, element.estado, '', element.Uid, element.foto
                ]))

                body = bodyt;
                bodyt = [];

                const doc = new jsPDF({
                    orientation: 'l',
                    format: 'a3'
                });
                doc.text('Items', 15, 10);

                autoTable(doc, {
                    head: [columns],
                    body: body,
                    didDrawCell: (data) => {
                        if (data.section === 'body' && data.column.index === 9 && data.row.raw[11] !=
                            null) {
                            var base64Img =
                                `${this.url_api}/files/images/${data.row.raw[10]}/.Metadatos/${data.row.raw[11]}`
                            doc.addImage(base64Img, data.cell.x + 2, data.cell.y + 2, 15, 15)
                        }
                    },
                    styles: {
                        cellPadding: 4
                    }
                });

                doc.save(`Items-${Date.now()}.pdf`);
            },

            async eliminarImage() {
                var imagenes = [];
                this.imgEliminar.forEach(e => {
                    imagenes.push(this.images[e]);
                });
                try {
                    await fetch(`${this.url_api}/item/itemImagen/`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            },
                            body: JSON.stringify(imagenes)
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body == 'exito') {
                                this.alerta = false;
                                this.textoAlerta = 'imagen(es) eliminada(s) con Exito!';
                                this.colorAlerta = 'warning'
                                this.alerta = true;
                                this.dialogImage = false;
                                this.listarItem();
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            } else if (response.error) {
                                this.alerta = false;
                                this.textoAlerta = 'Error al eliminar!';
                                this.colorAlerta = 'error'
                                this.alerta = true;
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            miles() {
                let numberr = this.editedItem.valor;
                let num = numberr.toString();
                if (num.length > 3) {
                    for (let index = 0; index < (numberr.split(".").length - 1 + 1); index++) {
                        num = num.replace('.', '');
                    }
                }

                let formatted = new Intl.NumberFormat('es-CO', {
                    currency: 'COP',
                }).format(parseInt(num));
                if (formatted == 'NaN') {
                    formatted = 0
                }
                this.editedItem.valor = formatted;
            },

            vistaPrevia() {
                this.url = [];
                this.urlE = [];
                this.image.forEach(element => {
                    this.url.push({
                        'src': URL.createObjectURL(element)
                    })
                });
                this.imageE.forEach(element => {
                    this.urlE.push({
                        'src': URL.createObjectURL(element)
                    })
                });
            },


            otroUso() {
                this.formUso = 0
                if (this.itemUso == 4) {
                    this.formUso = 1
                    this.tituloFormUso = 'Otro uso'
                }
            },

            async listarItem() {
                try {
                    await fetch(`${this.url_api}/item`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.activos = response.body;
                                this.tipoItem();
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            tipoItem() {
                for (let index = 1; index <= this.activos.length; index++) {
                    let numero = parseFloat((index) / 10000).toFixed(4)
                    let cadena2 = numero.toString();
                    let num = cadena2.replaceAll('.', '');
                    // let codigo = this.itemTipo.alias + '-' + num
                    let codigo = 'EW-' + num
                    const resultado = this.activos.find(item => item.codigo === codigo);
                    if (!resultado) {
                        this.editedItem.codigo = codigo;
                        break;
                    }
                }
            },
            async todasImagenes() {
                this.allImages = [];
                try {
                    await fetch(`${this.url_api}/item/imagen`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.allImages = response.body.filter(element => element)
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            async galeriaImagen(item) {
                this.images = [];
                this.imgEliminar = [];
                this.tituloimagen = item.codigo;
                try {
                    this.images = this.allImages.filter(element => element.Uid_item == item.Uid);
                } catch (error) {
                    console.log(error);
                }
            },

            async formatos(item) {
                this.dialogFormato = true;
                this.tituloimagen = item.codigo;
                await this.inspeccion(item);
                await this.documento(item);
            },

            async inspeccion(item) {
                try {
                    if (this.filesAll.length > 0) {
                        this.files = this.filesAll.filter(element => element.item == item.Uid);
                    } else {
                        await fetch(`${this.url_api}/inspeccion`, {
                                headers: {
                                    'Content-Type': 'application/json',
                                    "auth-token": this.token
                                }
                            }).then(res => res.json())
                            .catch(error => console.error('Error:', error))
                            .then(response => {
                                if (response.body) {
                                    this.filesAll = response.body;
                                    this.files = this.filesAll.filter(element => element.item == item.Uid);
                                } else if (response.error == ('!token' ||
                                        'tokenNull')) {
                                    this.cerrarSesion();
                                }
                            });
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            async documento(item) {
                try {
                    if (this.documentosAll.length > 0) {
                        this.documentos = this.documentosAll.filter(element => element.item == item.Uid);
                    } else {
                        await fetch(`${this.url_api}/documento`, {
                                headers: {
                                    'Content-Type': 'application/json',
                                    "auth-token": this.token
                                }
                            }).then(res => res.json())
                            .catch(error => console.error('Error:', error))
                            .then(response => {
                                if (response.body) {
                                    this.documentosAll = response.body;
                                    this.documentos = this.documentosAll.filter(element => element.item == item
                                        .Uid);
                                    console.log(this.documentos);
                                } else if (response.error == ('!token' ||
                                        'tokenNull')) {
                                    this.cerrarSesion();
                                }
                            });
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            async hojaVida(item) {
                try {
                    const items = [];
                    this.galeriaImagen(item);
                    items.push({
                        ...item
                    });
                    items[0].foto = this.images;
                    await fetch(`${this.url_api}/item/file/`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            },
                            body: JSON.stringify(items[0])
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                window.location.href =
                                    `${this.url_api}/files/formatos/${response.body}`
                            } else if (response.error == ('!token' ||
                                    'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            async listarTipItem() {
                try {
                    await fetch(`${this.url_api}/tipo_item`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.tiposItems = response.body;
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            async listarEstadoItem() {
                try {
                    await fetch(`${this.url_api}/estado_item`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.estadosItems = response.body;
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            async listarMarcaItem() {
                try {
                    await fetch(`${this.url_api}/marca`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.marcas = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            async listarProveedorItem() {
                try {
                    await fetch(`${this.url_api}/proveedor`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.proveedores = response.body
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            async listarSede() {
                try {
                    await fetch(`${this.url_api}/sede`, {
                            headers: {
                                'Content-Type': 'application/json',
                                "auth-token": this.token
                            }
                        }).then(res => res.json())
                        .catch(error => console.error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.sedes = response.body;
                            } else if (response.error == ('!token' || 'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            async listarBodega() {
                // this.bodegas = [];
                this.isEditing = true
                if (this.sede) {
                    this.sedes.filter(element => {
                        if (element.nombre == this.sede) {
                            this.itemSede = element.Uid;
                        }
                    })
                }
                if (this.itemSede) {
                    try {
                        if (this.bodegasAll.length > 0) {
                            this.bodegas = this.bodegasAll.filter(element => element.sede ==
                                this.itemSede);

                            if (this.sede) {
                                this.fBodegas = this.bodegas;
                            } else {
                                this.fBodegas = [];
                            }

                            if (this.itemBodega > 0) {
                                this.bodegas.forEach(element => {
                                    if (element.Uid == this.itemBodega) {
                                        this.itemBodega = element.Uid;
                                    }
                                });
                            }
                        } else {
                            await fetch(`${this.url_api}/bodega`, {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "auth-token": this.token
                                    }
                                }).then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body) {
                                        if (this.itemSede) {
                                            this.bodegasAll = response.body;

                                            this.bodegas = this.bodegasAll.filter(element => element.sede ==
                                                this.itemSede);

                                            if (this.sede) {
                                                this.fBodegas = this.bodegas;
                                            } else {
                                                this.fBodegas = [];
                                            }

                                            if (this.itemBodega > 0) {
                                                this.bodegas.forEach(element => {
                                                    if (element.Uid == this.itemBodega) {
                                                        this.itemBodega = element.Uid;
                                                    }
                                                });
                                            }
                                        }
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    }
                                });
                        }
                    } catch (error) {
                        console.error('Error:', error);
                    }
                }
            },

            async listarFabricante() {
                try {
                    await fetch(
                            `${this.url_api}/fabricante`, {
                                headers: {
                                    'Content-Type': 'application/json',
                                    "auth-token": this.token
                                }
                            }).then(res => res.json())
                        .catch(error => console.error(
                            'Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.fabricantes =
                                    response.body
                            } else if (response.error ==
                                ('!token' ||
                                    'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            async listarUso() {
                try {
                    await fetch(
                            `${this.url_api}/uso_item`, {
                                headers: {
                                    'Content-Type': 'application/json',
                                    "auth-token": this
                                        .token
                                }
                            }).then(res => res.json())
                        .catch(error => console.error(
                            'Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.usos = response
                                    .body
                            } else if (response
                                .error == (
                                    '!token' ||
                                    'tokenNull')) {
                                this.cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            async listarTipoEquipo() {
                try {
                    await fetch(
                            `${this.url_api}/tipo_equipo`, {
                                headers: {
                                    'Content-Type': 'application/json',
                                    "auth-token": this
                                        .token
                                }
                            }).then(res => res
                            .json())
                        .catch(error => console
                            .error('Error:', error))
                        .then(response => {
                            if (response.body) {
                                this.tiposEquipos =
                                    response
                                    .body
                            } else if (response
                                .error == (
                                    '!token' ||
                                    'tokenNull')
                            ) {
                                this
                                    .cerrarSesion();
                            }
                        });
                } catch (error) {
                    console.error('Error:', error);
                }
            },

            galeria(item) {
                this.tituloGaleria = item.name;
                this.dialog1 = true;
            },

            anterior(valor) {
                if (valor <= 0) {
                    this.model = this.images
                        .length - 1;
                } else {
                    this.model = valor - 1;
                }
            },

            siguiente(valor) {
                if (valor >= this.images.length -
                    1) {
                    this.model = 0;
                } else {
                    this.model = valor + 1;
                }
            },

            editItem(item) {
                this.tiposItems.forEach(element => {
                    if (element.Uid == item.Uid_tipoItem) {
                        this.itemTipo = {
                            Uid: element
                                .Uid,
                            nombre: element
                                .nombre
                        };
                        // this.tipoItem();
                    }
                });

                this.estadosItems.forEach(
                    element => {
                        if (element.Uid == item
                            .Uid_estado) {
                            this.itemEstado =
                                element.Uid;
                        }
                    });

                this.tiposEquipos.forEach(
                    element => {
                        if (element.Uid == item
                            .Uid_tipoEquipo) {
                            this.itemTipoEq =
                                element.Uid;
                        }
                    });

                this.marcas.forEach(element => {
                    if (element.Uid == item
                        .Uid_marca) {
                        this.itemMarca =
                            element.Uid;
                    }
                });

                this.proveedores.forEach(
                    element => {
                        if (element.Uid == item
                            .Uid_proveedor) {
                            this.itemProveedor =
                                element.Uid;
                        }
                    });

                this.fabricantes.forEach(
                    element => {
                        if (element.Uid == item
                            .Uid_fabricante) {
                            this.itemFabricante = {
                                Uid: element
                                    .Uid,
                                nombre: element
                                    .nombre
                            };
                        }
                    });

                this.sedes.forEach(element => {
                    if (element.Uid == item
                        .Uid_sede) {
                        this.itemSede =
                            element.Uid;
                        this.listarBodega();
                    }
                });

                this.itemBodega = item.Uid_bodega;

                this.usos.forEach(element => {
                    if (element.Uid == item
                        .Uid_uso) {
                        this.itemUso =
                            element.Uid;
                    }
                });

                if (item.otroUso) {
                    this.otroUso()
                    this.editedItem.otroUso = item
                        .otroUso;
                }

                this.editedIndex = this.activos
                    .indexOf(item);
                this.editedItem = Object.assign({},
                    item);
                this.dialog = true;
                this.UiItem = item.Uid
                var formatted = new Intl
                    .NumberFormat('es-CO', {
                        currency: 'COP',
                    }).format(parseInt(this
                        .editedItem.valor));
                this.editedItem.valor = formatted;

            },

            deleteItem(item) {
                this.editedIndex = this.activos
                    .indexOf(item);
                this.editedItem = Object.assign({},
                    item);
                this.dialogDelete = true;
                this.UiItem = item.Uid
            },

            async deleteItemConfirm() {
                try {
                    await fetch(
                            `${this.url_api}/item/${this.UiItem}`, {
                                method: 'DELETE',
                                headers: {
                                    'Content-Type': 'application/json',
                                    "auth-token": this
                                        .token
                                }
                            }).then(res => res
                            .json())
                        .catch(error => console
                            .error('Error:',
                                error))
                        .then(response => {
                            if (response
                                .body ==
                                'exito') {
                                this.alerta =
                                    false;
                                this.textoAlerta =
                                    'Dato eliminado con Exito!';
                                this.colorAlerta =
                                    'warning'
                                this.alerta =
                                    true;
                                this.activos
                                    .splice(
                                        this
                                        .editedIndex,
                                        1);
                            } else if (
                                response
                                .error == (
                                    '!token' ||
                                    'tokenNull'
                                )) {
                                this
                                    .cerrarSesion();
                            } else if (
                                response
                                .error) {
                                this.alerta =
                                    false;
                                this.textoAlerta =
                                    'Error al eliminar!';
                                this.colorAlerta =
                                    'error'
                                this.alerta =
                                    true;
                            }
                        });
                } catch (error) {
                    console.error('Error:',
                        error);
                }
                this.closeDelete();
            },

            close() {
                this.$refs.form.reset()
                this.formTipo = 0;
                this.isEdiCodigo = false
                this.tituloFormUso = 'Uso'
                this.formUso = 0
                this.dialog = false;
                this.$nextTick(() => {
                    this.editedItem =
                        Object
                        .assign({}, this
                            .defaultItem
                        );
                    this.editedIndex = -
                        1;
                });
            },

            closeDelete() {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem =
                        Object
                        .assign({}, this
                            .defaultItem
                        );
                    this.editedIndex = -
                        1;
                });
            },

            async guardar() {
                if (this.$refs.form
                    .validate()) {
                    let certifica = this
                        .editedItem
                        .certificacion
                    if (certifica != '1') {
                        certifica = 0
                    }

                    let manu = this
                        .editedItem.manual
                    if ((manu != '1')) {
                        manu = 0
                    }

                    let itemUso = 4
                    if (this.itemUso !=
                        null) {
                        itemUso = this
                            .itemUso
                    }

                    var numberr = this
                        .editedItem.valor;
                    var num = numberr
                        .toString();
                    if (num.length > 3) {
                        for (let index =
                                0; index < (
                                numberr
                                .split(".")
                                .length -
                                1 + 1
                            ); index++) {
                            num = num
                                .replace(
                                    '.', ''
                                );
                        }
                    }
                    let formData =
                        new FormData();
                    this.image.forEach(
                        element => {
                            formData
                                .append(
                                    'image',
                                    element
                                );
                        });
                    this.imageE.forEach(
                        element => {
                            formData
                                .append(
                                    'imageE',
                                    element
                                );
                        });
                    formData.append(
                        'bodega', this
                        .itemBodega);
                    formData.append(
                        'proveedor',
                        this
                        .itemProveedor);
                    formData.append('marca',
                        this.itemMarca);
                    formData.append(
                        'usuario', JSON
                        .parse(this
                            .usuario)
                        .usuario);
                    formData.append(
                        'tipoItem', this
                        .itemTipo.Uid);
                    formData.append(
                        'codigo', this
                        .editedItem
                        .codigo);
                    formData.append(
                        'fechaCompra',
                        this.editedItem
                        .fechaCompra);
                    formData.append(
                        'numeroFactura',
                        this.editedItem
                        .factura);
                    formData.append(
                        'descripcion',
                        this.editedItem
                        .descripcion);
                    formData.append(
                        'modelo', this
                        .editedItem
                        .modelo);
                    formData.append(
                        'serial', this
                        .editedItem
                        .serial);
                    formData.append('lote',
                        this.editedItem
                        .lote);
                    formData.append('valor',
                        num);
                    formData.append(
                        'vidaUtil', this
                        .editedItem
                        .vidaUtil);
                    formData.append(
                        'fechaFabricacion',
                        this.editedItem
                        .fechaFabricacion
                    );
                    formData.append(
                        'fechaPuestaUso',
                        this.editedItem
                        .fechaUso);
                    formData.append(
                        'cumpleNormas',
                        this.editedItem
                        .cumpleNorma);
                    formData.append(
                        'capacidad',
                        this.editedItem
                        .capacidad);
                    formData.append(
                        'otrasEspecificacionesTecnicas',
                        this.editedItem
                        .especificaciones
                    );
                    formData.append(
                        'manualFabricante',
                        this.editedItem
                        .manual);
                    formData.append(
                        'certificaionFabricante',
                        this.editedItem
                        .certificacion);
                    formData.append(
                        'estado', this
                        .itemEstado);
                    formData.append(
                        'tipoEquipo',
                        this.itemTipoEq);
                    formData.append('uso',
                        itemUso);
                    formData.append(
                        'otroUso', this
                        .editedItem
                        .otroUso);
                    formData.append(
                        'nombreKit',
                        this.editedItem
                        .kit);
                    formData.append(
                        'informacionKit',
                        this.editedItem
                        .informacionKit);
                    if (this.editedIndex > -
                        1) {
                        try {
                            await fetch(
                                    `${this.url_api}/item/${this.UiItem}`, {
                                        method: 'POST',
                                        headers: {
                                            'Accept': 'application/json',
                                            "auth-token": this
                                                .token
                                        },
                                        body: formData
                                    }).then(
                                    res =>
                                    res
                                    .json())
                                .catch(
                                    error =>
                                    console
                                    .error(
                                        'Error:',
                                        error
                                    ))
                                .then(
                                    response => {
                                        if (response
                                            .body ==
                                            'exito'
                                        ) {
                                            this.alerta =
                                                false;
                                            this.textoAlerta =
                                                'Dato actualizado con Exito!';
                                            this.colorAlerta =
                                                'primary'
                                            this.alerta =
                                                true;
                                            this.UiItem =
                                                null;
                                            this
                                                .close();
                                            Object
                                                .assign(
                                                    this
                                                    .activos[
                                                        this
                                                        .editedIndex
                                                    ],
                                                    this
                                                    .editedItem
                                                );
                                            this
                                                .listarItem();
                                            this
                                                .todasImagenes();
                                        } else if (
                                            response
                                            .error ==
                                            ('!token' ||
                                                'tokenNull'
                                            )
                                        ) {
                                            this
                                                .cerrarSesion();
                                        } else if (
                                            response
                                            .error
                                        ) {
                                            this.alerta =
                                                false;
                                            this.textoAlerta =
                                                'Error al actualizar!';
                                            this.colorAlerta =
                                                'error'
                                            this.alerta =
                                                true;
                                        }
                                    });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                    } else {
                        try {
                            await fetch(`${this.url_api}/item`, {
                                    method: 'POST',
                                    headers: {
                                        'Accept': 'application/json',
                                        "auth-token": this.token
                                    },
                                    body: formData
                                })
                                .then(res => res.json())
                                .catch(error => console.error('Error:', error))
                                .then(response => {
                                    if (response.body == 'exito') {
                                        this.alerta = false;
                                        this.textoAlerta = 'Dato guardado con Exito!';
                                        this.colorAlerta = 'success';
                                        this.alerta = true;
                                        this.listarItem();
                                        this.todasImagenes();
                                        this.activos.push(this.editedItem);
                                        this.$refs.form.reset();
                                    } else if (response.error == ('!token' || 'tokenNull')) {
                                        this.cerrarSesion();
                                    } else if (response.error) {
                                        this.alerta = false;
                                        this.textoAlerta = 'Error al actualizar!';
                                        this.colorAlerta = 'error'
                                        this.alerta = true;
                                    }
                                });
                        } catch (error) {
                            console.error('Error:', error);
                        }
                    }
                }
            },
        },
    };
</script>